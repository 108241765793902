import React from "react";
function test() {

  return (
    <div>
      <span>test</span>
    </div>
  );
}

export default test;
